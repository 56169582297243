'use client'

import {
    Box,
    ButtonGroup,
    Button,
    Flex,
    useSteps,
} from '@chakra-ui/react'

import Stepper from './Stepper'
import Form1 from './Form1'
import Form2 from './Form2'
import Form3 from './Form3'

const steps = [
    { title: 'Dados', description: '' },
    { title: 'Pagamento', description: '' },
    { title: 'Emissão Online', description: '' },
];

export default function Multistep() {
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    });

    return (
        <>
            <Box
                borderWidth="1px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                maxWidth={800}
                minWidth={'70%'}
                p={6}
                m="10px auto"
                as="form">
                <Stepper activeStep={activeStep} steps={steps} />
                {activeStep === 1 || activeStep === 0 ? <Form1 /> : activeStep === 2 ? <Form2 /> : <Form3 />}
                <ButtonGroup mt="5%" w="100%">
                    <Flex w="100%" justifyContent="space-between">
                        <Flex>
                            <Button
                                onClick={() => {
                                    setActiveStep(activeStep - 1);
                                }}
                                isDisabled={activeStep === 0 || activeStep === 1}
                                colorScheme="teal"
                                variant="outline"
                                w="7rem"
                                mr="5%">
                                Voltar
                            </Button>
                            <Button
                                w="7rem"
                                isDisabled={activeStep === 3}
                                onClick={() => {
                                    setActiveStep(activeStep + 1);
                                }}
                                colorScheme="teal"
                                variant="solid">
                                Avançar
                            </Button>
                        </Flex>
                        {/* {step === 3 ? (
                            <Button
                                w="7rem"
                                colorScheme="red"
                                variant="solid"
                                onClick={() => {
                                    toast({
                                        title: 'Account created.',
                                        description: "We've created your account for you.",
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }}>
                                Submit
                            </Button>
                        ) : null} */}
                    </Flex>
                </ButtonGroup>
            </Box>
        </>
    )
}