'use client'
import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack
} from '@chakra-ui/react';
import {
    Link as RouteLink
} from "react-router-dom";

export default function Status() {
    return (
        <>
            <Container maxW={'3xl'}>
                <Stack
                    as={Box}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 20, md: 36 }}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        Status
                    </Heading>
                    <Text color={'gray.500'}>
                        Serviço Disponível.
                        Última Indisponibilidade.
                        Última Instabilidade.
                    </Text>
                    <Stack
                        direction={'column'}
                        spacing={3}
                        align={'center'}
                        alignSelf={'center'}
                        position={'relative'}>
                        <RouteLink to='/'>
                            <Button
                                colorScheme={'green'}
                                bg={'green.400'}
                                rounded={'full'}
                                px={6}
                                _hover={{
                                    bg: 'green.500',
                                }}>
                                Voltar
                            </Button>
                        </RouteLink>
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}