'use client'
import {
    Image
} from '@chakra-ui/react'
import LogoPng from './../../assets/logo.png'
import {
    Link as RouteLink,
} from "react-router-dom";

export default function Logo({ maxHeight = 70 }) {
    return (
        <RouteLink to='/'>
            <Image src={LogoPng} maxHeight={maxHeight} />
        </RouteLink>
    )
}