import React from 'react';
import { useColorMode, useColorModeValue, IconButton, Stack } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import Logo from './components/shared/Logo';

export const ColorModeSwitcher = props => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <>
      <Stack direction={'row'}>
        <Logo />
        <IconButton
          size="md"
          fontSize="lg"
          aria-label={`Mudar para ${text}`}
          variant="ghost"
          color="current"
          marginLeft="2"
          onClick={toggleColorMode}
          icon={<SwitchIcon />}
          {...props}
        />
      </Stack>
    </>
  );
};
