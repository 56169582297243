import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Home from './components/ui/Home/Home';
import Carrinho from './components/ui/Carrinho/Carrinho';
// import Footer from './components/ui/Footer/Footer';
import Sobre from './components/ui/Sobre/Sobre';
import Contato from './components/ui/Contato/Contato';
import Status from './components/ui/Status/Status';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import SaibaMais from './components/ui/SaibaMais/SaibaMais';

// https://chakra-ui.com/docs/components
// https://chakra-templates.dev/page-sections/hero

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box textAlign="center" fontSize="xl">
          <Grid minH="100vh" p={3}>
            <ColorModeSwitcher />
            <Switch>
              <Route path='/adquirir'>
                <Carrinho />
              </Route>
              <Route path='/sobre'>
                <Sobre />
              </Route>
              <Route path='/contato'>
                <Contato />
              </Route>
              <Route path='/status'>
                <Status />
              </Route>
              <Route path='/saiba-mais'>
                <SaibaMais />
              </Route>
              <Route path='/'>
                <Home />
              </Route>
            </Switch>
          </Grid>
        </Box>
        {/* <Footer /> */}
      </Router>
    </ChakraProvider>
  );
}

export default App;
