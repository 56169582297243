'use client'
import {
  Box,
  Heading,
  Container,
  Text,
  Stack
} from '@chakra-ui/react';

export default function Home() {
  return (
    <>
      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Certificado Digital A1 <br />
            <Text as={'span'} color={'green.400'}>
              100% Online (Em breve)
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            Gerar seu Certificado Digital agora é muito mais simples e pode ser feito totalmente online em 20 minutos ou menos*.
          </Text>
          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            {/* <RouteLink to='/adquirir'>
              <Button
                colorScheme={'green'}
                bg={'green.400'}
                rounded={'full'}
                px={6}
                _hover={{
                  bg: 'green.500',
                }}>
                Adquirir agora
              </Button>
            </RouteLink>
            <RouteLink to="/saiba-mais">
              <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Saiba mais
              </Button>
            </RouteLink> */}
            <Text color={'gray.500'} fontSize={'small'}>
              * Durante horário comercial entre 8h e 18h (Brasília). <br />Exceto em finais de semana e feriados. Tempo médio após a confirmação do pagamento <br />em condições normais de procura.
            </Text>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}